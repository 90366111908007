<template src="./GroupCreationRequests.html"></template>

<script>
import idiService from "../../services/Idi";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import { mapGetters } from "vuex";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "groupCreationRequests",
  components: {
    ChooseUniversity,
    ArrobaMedellinArrobitaNoData,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Lista de solicitudes de creación",
    },
    notFound: {
      type: String,
      default: "No hay solicitudes en éste momento",
    },
  },
  data() {
    return {
      requests: [],
      modalCreateGroup: false,
      modalRejectCreation: false,
      groupRequest: null,
      idGroup: null,
      institutionId: "",
      role: "",
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.getInfoRequest();
  },
  methods: {
    async getInfoRequest() {
      try {
        const infoRequest = {
          typeRequest: "create",
          institutionId: this.institutionId,
        };
        this.requests = await idiService.groupRequestList(infoRequest);
      } catch (error) {
        console.error(error);
      }
    },
    showModalCreateGroup(id) {
      this.modalCreateGroup = true;
      this.idGroup = id;
    },
    showModalRejectCreation(id) {
      this.modalRejectCreation = true;
      this.idGroup = id;
    },
    async acceptCreateGroup() {
      try {
        const tokenPhp = this.$store.getters.getTokenControllerPhp.toString();
        const infoReject = {
          statusRequest: "agree",
          groupChangeRequestId: Number(this.idGroup),
          token: tokenPhp,
        };
        await idiService.agreeOrRejectGroup(infoReject);
        this.getInfoRequest();
        this.modalCreateGroup = false;
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se acepto la creación con éxito.",
          type: "is-success",
        });
      } catch (error) {
        console.error(error);
      }
    },
    async rejectCreationGroup() {
      try {
        const tokenPhp = this.$store.getters.getTokenControllerPhp.toString();
        const infoReject = {
          statusRequest: "rejected",
          groupChangeRequestId: Number(this.idGroup),
          token: tokenPhp,
        };
        await idiService.agreeOrRejectGroup(infoReject);
        this.getInfoRequest();
        this.modalRejectCreation = false;
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se rechazo la creación con éxito.",
          type: "is-success",
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getInfoRequest();
    },
  },
};
</script>

<style lang="scss" scoped src="./GroupCreationRequests.scss"></style>